import React, { useState } from 'react';
import { TrashIcon } from '@heroicons/react/24/solid';
import { symbol_image_path, show_symbol_path, watchlist_remove_api_path } from "../routes";
import { navigate } from '../navigator';
import SparklineChart from './sparkline_chart';
import { getColorClasses } from '../utils/colors';
import Blaze from '../blaze';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useQueryClient } from '@tanstack/react-query';
import { WATCHLIST_LIST_QUERY_KEY } from '../hooks/use_watchlist';
import { Stock } from '../types/stock';
import { VolatilityInfo } from './volatility_info';

const StockInfo = ({ stock }: { stock: Stock }) => {
  const imageUrl = symbol_image_path(stock.symbol)
  return (
    <div className="flex flex-row items-center w-[100px] h-12 mx-2">
      <div className="w-[30px] h-[30px] mr-2 flex items-center">
        <img src={imageUrl} alt={stock.name} className="w-7 h-7 block rounded-full overflow-hidden" />
      </div>
      <div className="w-[60px] h-12 flex flex-col items-start text-left">
        <div className="flex flex-row items-center gap-x-1">
          <span className={`${getColorClasses(stock.change).text}`}>{parseFloat(stock.change) >= 0 ? '▲' : '▼'}</span>
          <span className="font-bold text-base text-dblue-200">{stock.symbol}</span>
        </div>
        <div className="text-zinc-500 text-sm truncate min-w-0 max-w-[60px]">{stock.name}</div>
      </div>
    </div>
  )
};

const PriceChange = ({ stock }: { stock: Stock }) => {
  const colors = getColorClasses(stock.change);
  return (
    <div className={`w-[70px] mr-2 text-sm flex flex-col text-right ${colors.text}`}>
      <span className={`py-0.5 px-2 rounded-lg text-[12pt] font-medium ${colors.background}`}>
        {stock.change >= 0 ? '+' : ''}{stock.changePercent.toFixed(2)}%
      </span>
      <span className="hidden mr-2 text-zinc-500">{stock.iv30 ? <span className="text-yellow-500">{stock.iv30.toFixed(0)} iv</span> : '-'}</span>
    </div>)
};

const PriceInfo = ({ stock }: { stock: Stock }) => {
  const changeColor = getColorClasses(stock.change).text;
  return (
    <div className="w-12 text-right mr-2">
      <div className="text-zinc-200 text-[12pt]">{stock.latestPrice.toFixed(2)}</div>
      <div className={`-mt-0.5 text-sm flex items-center justify-end text-right gap-2 ${changeColor}`}>
        <span className='py-0.5'>{stock.change >= 0 ? '+' : ''}{stock.change.toFixed(2)}</span>
      </div>
    </div>
  )
};

const DeleteButton = ({ rowHeight, onClick }: { rowHeight: number, onClick: () => void }) => (
  <button
    className="bg-red-500 text-white flex items-center justify-center"
    style={{ height: `${rowHeight}px`, width: `${rowHeight}px` }}
    onClick={onClick}>
    <TrashIcon className="w-6 h-6" />
  </button>
);

const WatchlistItem = ({ stock, refetch, rowHeight = 66, showIV = false }: { stock: Stock, refetch: () => void, rowHeight?: number, showIV?: boolean }) => {
  const [isRemoving, setIsRemoving] = useState(false);
  const colors = getColorClasses(stock.change);
  const queryClient = useQueryClient();

  const handleDelete = () => {
    setIsRemoving(true);
    fetch(watchlist_remove_api_path({ symbol: stock.symbol }), {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': Blaze.csrfToken(),
      },
    }).then(response => response.json())
      .then(() => {
        queryClient.invalidateQueries({ queryKey: [WATCHLIST_LIST_QUERY_KEY] });
        refetch();
      });
  };

  const WatchlistRow = ({showIV = false}) => {
    return <div className={`cursor-pointer w-full flex flex-row items-start border-b border-black ${colors.background}`}
      onClick={() => navigate(show_symbol_path(stock.symbol))}>
      <div className={`w-0.5 h-16 ${colors.backgroundOpaque}`}></div>
      <div className="grow flex flex-row items-start gap-x-1 py-2">
        <StockInfo stock={stock} />
        <div className="grow h-10 items-center justify-center flex">
          <SparklineChart className="!w-[50px] h-6 mx-2" color={colors.chart} strokeWidth={1} symbol={stock.symbol} />
        </div>
        <PriceInfo stock={stock} />
        <PriceChange stock={stock} />
        {showIV && <VolatilityInfo stock={stock} />}
      </div>
    </div>;
  }

  return (
    <div className={`transition-all duration-300 ease-in-out ${isRemoving ? 'opacity-0 h-0' : `opacity-100 h-[${rowHeight}px]`}`}>
      <Swiper
        slidesPerView="auto"
        className="!w-full !h-full"
        allowTouchMove={true}
        resistance={true}
        resistanceRatio={0.85}
      >
        <SwiperSlide className="!w-full">
          <WatchlistRow showIV={showIV}/>
        </SwiperSlide>
        <SwiperSlide className="!w-fit pl-1">
          <div className="flex w-fit h-full items-center justify-center">
            <DeleteButton rowHeight={rowHeight - 2} onClick={handleDelete} />
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default WatchlistItem;
