import { QueryClient } from '@tanstack/react-query'

class Blaze {
  static #queryClient = null;

  static getQueryClient() {
    if (!this.#queryClient) {
      this.#queryClient = new QueryClient({
        defaultOptions: {
          queries: {
            staleTime: 1000 * 60 * 5, // 5 minutes
            retry: 2,
          },
        },
      });
    }
    return this.#queryClient;
  }

  static getVersion() {
    const metaElement = document.querySelector('meta[name="mrc-version"]')
    if (metaElement) {
      return metaElement.getAttribute("content")
    }
  }

  static updateVersion(latestVersion) {
    const metaElement = document.querySelector('meta[name="mrc-version"]')
    if (metaElement) {
      metaElement.setAttribute("content", latestVersion)
    }
  }

  static csrfToken() {
    return document.querySelector('[name="csrf-token"]')?.getAttribute('content') || ''
  }
}

window.Blaze = Blaze;
export default Blaze;
