import React, { useState } from 'react';
import { Stock } from '../types/stock';
import SlideTransition from './slide_transition';

const getIVCost = (stock: Stock): number => {
  const ratio = stock.iv_edge;
  // console.log(`${stock.symbol} ${stock.changePercent}% ${stock.iv30}iv ${ratio} ${getIvEdge(stock)}`);
  // Return 1-4 $ signs based on how expensive IV is compared to the move
  if (ratio < 0.33) return 4;  // Very expensive (actual move is much smaller than expected)
  if (ratio < 0.67) return 3;
  if (ratio < 1) return 2;
  return 1;  // Cheap IV (actual move is larger than expected)
}


const VolatilityInfo = ({ stock }: { stock: Stock }) => {
  const dollarSigns = getIVCost(stock);
  const color = dollarSigns >= 3 ? 'text-red-300' : dollarSigns === 2 ? 'text-yellow-400' : 'text-emerald-200';
  return <div className="w-12 pr-2 text-right text-zinc-500 text-[12pt] truncate">
    {stock.iv30 ? (
      <div className="flex flex-col items-end justify-end">
        <span className="text-base text-yellow-500">{stock.iv30.toFixed(0)} iv</span>
        <SlideTransition className=''>
          <div className={`text-sm ${color}`}>{Array(dollarSigns).fill('$').join('')}</div>
          {stock.iv_edge && <div className={`-mt-1 text-sm text-yellow-500`}>{stock.iv_edge.toFixed(1)}x</div>}
        </SlideTransition>
      </div>
    ) : '-'}
  </div>
}

export { VolatilityInfo, getIVCost };
