import React from 'react';
import DownArrowIcon from "../icons/down_arrow_icon";
import UpArrowIcon from "../icons/up_arrow_icon";
import {formatMoney} from "../utils";
import clsx from "clsx";

const AccountBalances = ({accountData, className}) => {
  const initialValue = accountData.total_equity - accountData.open_pl;
  const percentageChange = ((accountData.open_pl / initialValue) * 100).toFixed(1);
  const isPositive = accountData.open_pl > 0;

  return (
    <div className={clsx("border border-zinc-900 rounded-lg mb-2", className)}>
      <span className="mb-2 block w-full text-xs uppercase text-zinc-400 bg-zinc-900 text-center px-2 py-1 rounded-t-lg">
        Account Balances ({accountData.account_number ? `...${accountData.account_number.slice(-4)}` : "-"})
      </span>
      <div className="px-4 pb-2 flex flex-col text-xs">
        <div className="flex flex-col justify-between pt-1 pb-2">
          <div className="text-zinc-400 flex flex-col">
            <span className="text-xs">Market Value</span>
            <div className="flex flex-row items-center">
              <span className="font-bold text-zinc-200 text-2xl mr-2">{formatMoney(accountData.total_equity)}</span>
              {accountData.open_pl !== 0 && <>
                <div className="">{isPositive ? <UpArrowIcon/> : <DownArrowIcon color="text-rose-400"/>}</div>
                <span
                  className={`text-lg ${isPositive ? 'text-green-400' : 'text-rose-400'}`}>{formatMoney(accountData.open_pl, true)}</span>
                <span className={`ml-2 whitespace-nowrap rounded-lg px-2 text-xs/6 font-semibold ${isPositive
                  ? 'bg-green-400/10 text-green-400'
                  : 'bg-rose-400/10 text-rose-400'}`}>{`${isPositive ? "+" : ""}${percentageChange}%`}</span>
              </>}
            </div>
          </div>
        </div>
        <ul className="text-base flex pt-2 flex-col gap-y-0 divide-y-0 divide-zinc-800 border-t border-zinc-800">
          <li className="hidden flex justify-between text-zinc-400 pt-1">
            <span>Account Type</span>
            <span className="text-zinc-300">{accountData.account_type}</span>
          </li>
          <li className="flex justify-between text-zinc-400 pt-1">
            <span>Total Cash</span>
            <span className="text-zinc-300">{formatMoney(accountData.total_cash)}</span>
          </li>
          <li className="flex justify-between text-zinc-400 pt-1">
            <span>Long Market Value</span>
            <span className="text-zinc-300">{formatMoney(accountData.long_market_value)}</span>
          </li>
          {accountData.margin?.short_market_value > 0 && (
            <li className="flex justify-between text-zinc-400 pt-1">
              <span>Short Market Power</span>
              <span className="text-zinc-300">{formatMoney(accountData.margin.short_market_value)}</span>
            </li>
          )}
          {accountData.margin?.stock_buying_power > 0 && (
            <li className="flex justify-between text-zinc-400 pt-1">
              <span>Buying Power</span>
              <span className="text-zinc-300">{formatMoney(accountData.margin.stock_buying_power)}</span>
            </li>
          )}
          {accountData.margin?.fed_call > 0 && (
            <li className="flex justify-between text-zinc-400 pt-1">
              <span>Fed Call</span>
              <span className="text-zinc-300">{formatMoney(accountData.margin.fed_call)}</span>
            </li>
          )}
          {accountData.margin?.maintenance_call > 0 && (
            <li className="flex justify-between text-zinc-400 pt-1">
              <span>Maintenance Call</span>
              <span className="text-zinc-300">{formatMoney(accountData.margin.maintenance_call)}</span>
            </li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default AccountBalances;