import React, { useEffect, useRef } from 'react';
import { init as echartsInit } from 'echarts';
import { market_intraday_api_path } from '../routes';
import SparklineMock from './sparkline_mock';
import { useQuery } from '@tanstack/react-query';

const SparklineChart = ({ symbol, className = '', color = '#2fe898', strokeWidth = 2, maxSamples = 20 }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const { data: marketIntradayData, isPending } = useQuery({
    queryKey: ["market_intraday", symbol],
    queryFn: () => fetch(market_intraday_api_path({ symbols: [symbol] })).then(res => res.json())
  });

  const chartData = marketIntradayData?.intraday?.[symbol]?.close || [];
  const sampledChartData = chartData.length > maxSamples
    ? chartData.filter((_, index) => index % Math.ceil(chartData.length / maxSamples) === 0)
    : chartData;

  useEffect(() => {
    if (chartRef.current && chartRef.current.clientHeight > 0 && sampledChartData?.length > 0) {
      chartInstance.current = echartsInit(chartRef.current);
      chartInstance.current.setOption({
        animation: false,
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        },
        xAxis: {
          type: 'category',
          show: false,
          boundaryGap: false,
          axisPointer: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          show: false,
          min: 'dataMin',
          max: 'dataMax'
        },
        series: [{
          data: sampledChartData,
          type: 'line',
          showSymbol: false,
          symbol: 'none',
          lineStyle: {
            color,
            width: strokeWidth
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: `${color}4D` // 30% opacity
              }, {
                offset: 1,
                color: `${color}00` // 0% opacity
              }]
            }
          }
        }]
      });
    }

    const handleResize = () => chartInstance.current?.resize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chartInstance.current?.dispose();
      chartInstance.current = null;
    };
  }, [symbol, sampledChartData, strokeWidth]);

  if (isPending) {
    return <SparklineMock strokeWidth={strokeWidth} className={className} color={color} />;
  }

  return (
    <div className={`w-full ${className}`} ref={chartRef} />
  );
};

export default SparklineChart; 