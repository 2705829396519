import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

interface Stock {
  symbol: string;
  name: string;
}

export const useStockSearch = () => {
  const [stockResults, setStockResults] = useState<Stock[]>([]);

  const { data: tickerData, isPending: tickerDataPending } = useQuery({
    queryKey: ['tickers'],
    queryFn: () =>
      fetch("/listed-tickers.json", {
        headers: {
          'Accept-Encoding': 'gzip'
        },
      }).then((response) => response.json())
  });

  const searchWithQuery = (query: string) => {
    if (!query) {
      setStockResults([]);
      return;
    }

    const searchQuery = query.toUpperCase();
    let data = tickerData?.map((x: Stock) => x) || [];

    const sortedResults = data
      .map(ticker => {
        const symbol = ticker.symbol.toUpperCase();
        const name = ticker.name ? ticker.name.toUpperCase() : "-";
        let match = symbol.indexOf(searchQuery);

        // Check name if no symbol match
        if (match < 0) {
          match = name.indexOf(searchQuery);
        }

        // Prioritize exact prefix matches
        if (searchQuery === symbol.substring(0, searchQuery.length)) {
          match = -5000;
        }
        // Highest priority for exact matches
        if (symbol === searchQuery) {
          match = -10000;
        }

        return { ...ticker, match };
      })
      .filter(ticker => ticker.match !== -1)
      .sort((a, b) => a.match - b.match)
      .slice(0, 10);

    setStockResults(sortedResults);
  };

  return {
    stockResults, 
    searchWithQuery,
    tickerData,
    tickerDataPending,
  };
}; 