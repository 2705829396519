import React from 'react';
import { useQuery } from "@tanstack/react-query"
import { trading_summary_api_path, show_positions_path } from "../routes"
import currency from 'currency.js'
import { navigate } from '../navigator'
import SlideTransition from './slide_transition'
import SparklineMock from './sparkline_mock'
import PortfolioSparkline from './portfolio_sparkline'
import Tracker from './tracker'

const LoadingView = () => {
  return (<div className="mx-4 border border-zinc-900 p-4 rounded-lg text-zinc-200">
    <div className='flex flex-row justify-between items-center'>
      <div className="flex flex-col items-start">
        <div>
          <div className="text-zinc-400 text-sm mb-0.5">Market Value</div>
          <div className="text-3xl bg-zinc-800 w-24 h-8 rounded animate-pulse"></div>
        </div>
        <div className="text-left mt-2">
          <div className="text-zinc-400 font-semibold space-x-2 text-sm flex flex-row items-center">
            <div className="bg-zinc-800 w-16 h-4 rounded animate-pulse"></div>
            <div className="font-light text-xs text-zinc-400">Today</div>
          </div>
        </div>
      </div>
      <div className="-mt-1 text-zinc-500 text-2xl font-light cursor-pointer select-none">›</div>
    </div>
  </div>
  );
};

const PortfolioPreview = () => {
  const { isPending, data } = useQuery({
    queryKey: ["trader_summary_api"],
    queryFn: () => fetch(trading_summary_api_path()).then(res => res.json())
  })

  if (isPending) {
    return (<LoadingView />);
  }

  const isPositive = data.day_change_pnl > 0
  const percentageChange = (data.day_change_pnl / (data.balances.total_equity - data.day_change_pnl)) * 100

  const textColorClassName = isPositive ? "text-green-500" : "text-rose-500";
  return (
    <div onClick={() => {
        navigate(show_positions_path())
        Tracker.track('portfolio_preview.click')
      }}
      className="cursor-pointer mx-4 border border-zinc-800 p-4 rounded-lg text-white font-chalk">
      <div className='flex flex-row justify-between items-center gap-x-4'>
        <div className="flex flex-col items-start">
          <div>
            <div className="text-zinc-400 text-sm mb-0.5">Market Value</div>
            <div className="text-3xl -mt-1">{currency(data.balances.total_equity).format()}</div>
          </div>
          <div className="text-left mt-0.5">
            <div className={`${textColorClassName} relative text-base font-semibold space-x-2 flex flex-row items-center`}>
              <div className="">{isPositive ? "▲" : "▼"}</div>
              <SlideTransition className="">
                <div className="">
                  <span>{isPositive ? "+" : ""}</span>
                  <span>{currency(data.day_change_pnl.toFixed(2)).format()}</span>
                </div>
                <div className=''>
                  <span>{isPositive ? "+" : ""}</span>
                  <span>{percentageChange.toFixed(2)}%</span>
                </div>
              </SlideTransition>
              <div className="font-light text-sm text-zinc-400">Today</div>
            </div>
          </div>
        </div>
        <PortfolioSparkline />
        <div className="-mt-1 text-zinc-600 text-2xl font-light cursor-pointer select-none">›</div>
      </div>
    </div>
  );
};

export default PortfolioPreview;
