import React from 'react'
import {
  ChartBarSquareIcon as ChartBarSquareOutline,
  CreditCardIcon as CreditCardOutline,
  BookOpenIcon as BookOpenOutline,
  DocumentChartBarIcon as DocumentChartOutline,
  QueueListIcon as QueueListOutline,
  UserGroupIcon as UserGroupOutline,
  BeakerIcon as BeakerOutline,
  UserIcon as UserOutline,
  SparklesIcon as SparklesOutline,
  BoltIcon as BoltOutline,
  HomeIcon as HomeOutline,
  FireIcon as FireOutline,
  MapIcon as MapOutline,
  MagnifyingGlassIcon as SearchIcon,
} from '@heroicons/react/24/outline'

import {
  ChartBarSquareIcon as ChartBarSquareSolid,
  CreditCardIcon as CreditCardSolid,
  BookOpenIcon as BookOpenSolid,
  DocumentChartBarIcon as DocumentChartSolid,
  QueueListIcon as QueueListSolid,
  UserGroupIcon as UserGroupSolid,
  BeakerIcon as BeakerSolid,
  UserIcon as UserSolid,
  SparklesIcon as SparklesSolid,
  BoltIcon as BoltSolid,
  HomeIcon as HomeSolid,
  FireIcon as FireSolid,
  MapIcon as MapSolid,
} from '@heroicons/react/24/solid'

const iconMap = {
  chart_square: { solid: ChartBarSquareSolid, outline: ChartBarSquareOutline },
  credit_card: { solid: CreditCardSolid, outline: CreditCardOutline },
  book_open: { solid: BookOpenSolid, outline: BookOpenOutline },
  document_report: { solid: DocumentChartSolid, outline: DocumentChartOutline },
  collection: { solid: QueueListSolid, outline: QueueListOutline },
  user_group: { solid: UserGroupSolid, outline: UserGroupOutline },
  beaker: { solid: BeakerSolid, outline: BeakerOutline },
  user: { solid: UserSolid, outline: UserOutline },
  sparkles: { solid: SparklesSolid, outline: SparklesOutline },
  bolt: { solid: BoltSolid, outline: BoltOutline },
  home: { solid: HomeSolid, outline: HomeOutline },
  fire: { solid: FireSolid, outline: FireOutline },
  map: { solid: MapSolid, outline: MapOutline },
  search: { solid: SearchIcon, outline: SearchIcon },
}

const NavigationIcon = ({ icon, isSelected, className = "" }) => {
  const iconKey = icon.split('/')[1]
  const icons = iconMap[iconKey] || { solid: BoltSolid, outline: BoltOutline }
  const Icon = isSelected ? icons.solid : icons.outline
  
  return <Icon className={`w-7 h-7 ${className}`} />
}

export default NavigationIcon