import { MagnifyingGlassIcon } from '@heroicons/react/24/solid'
import React from 'react';

const SearchInput = ({inputClassName = ""}) => {
  const onClick = () => {
    const autocompleteButton = document.querySelector('.aa-Autocomplete');
    if (autocompleteButton) {
      autocompleteButton.children[0].click();
    }
  }
  return (
    <div onClick={onClick} className={`cursor-pointer bg-zinc-900 hover:bg-zinc-800 rounded-lg flex flex-row items-center ${inputClassName}`}>
      <div className='ml-2 w-4 h-4 text-zinc-500'><MagnifyingGlassIcon /></div>
      <div className="ml-2 border-0 py-1.5 text-zinc-500" placeholder=''>Search by name, ticker</div>
    </div>
  )
}

export default SearchInput;