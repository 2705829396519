import React, { useEffect, useRef } from 'react';
import { init as echartsInit } from 'echarts';
import { trading_balance_history_api_path } from '../routes';
import SparklineMock from './sparkline_mock';
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';

const PortfolioSparkline = ({ className = 'max-w-36 h-16', color = '#2fe898', strokeWidth = 2, maxSamples = 20 }) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  const { data, isPending } = useQuery({
    queryKey: ["trading_balance_history_api"],
    queryFn: () => {
      const startTime = moment().subtract(1, 'week').toISOString()
      return fetch(trading_balance_history_api_path({ start_time: startTime })).then(res => res.json());
    }
  });

  const balanceHistory = data?.balance_history || [];
  const sampledData = balanceHistory.length > maxSamples
    ? balanceHistory.filter((_, index) => index % Math.ceil(balanceHistory.length / maxSamples) === 0)
    : balanceHistory;

  useEffect(() => {
    if (chartRef.current && chartRef.current.clientHeight > 0) {
      chartInstance.current = echartsInit(chartRef.current);
      chartInstance.current.setOption({
        animation: false,
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        },
        xAxis: {
          type: 'category',
          show: false,
          boundaryGap: false,
          data: sampledData.map(item => item.time),
          axisPointer: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          show: false,
          min: 'dataMin',
          max: 'dataMax'
        },
        series: [{
          data: sampledData.map(item => item.value),
          type: 'line',
          showSymbol: false,
          symbol: 'none',
          lineStyle: {
            color,
            width: strokeWidth
          },
          areaStyle: {
            color: {
              type: 'linear',
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [{
                offset: 0,
                color: `${color}4D` // 30% opacity
              }, {
                offset: 1,
                color: `${color}00` // 0% opacity
              }]
            }
          }
        }]
      });
    }

    const handleResize = () => chartInstance.current?.resize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      chartInstance.current?.dispose();
      chartInstance.current = null;
    };
  }, [chartRef.current, sampledData, data, strokeWidth]);

  if (isPending || sampledData?.length < 5) {  // not enough data to show
    return null;
  } else {
    return (
      <div className={`${className} w-full`} ref={chartRef} />
    );
  }
};

export default PortfolioSparkline;
