import React, { useEffect, useRef } from 'react';
import { init as echartsInit } from 'echarts';
import { useQuery } from '@tanstack/react-query';
import { market_quote_api_path, show_symbol_path } from '../routes';
import { navigate } from "../navigator"

const MarketSectors = ({ className = '' }) => {
  const chartRef = useRef(null);

  const sectorData = [
    { name: '💻  Technology', etf: 'XLK' },
    { name: '💰  Financials', etf: 'XLF' },
    { name: '🏥  Health Care', etf: 'XLV' },
    { name: '🛒  Consumer Discretionary', etf: 'XLY' },
    { name: '📞  Communication', etf: 'XLC' },
    { name: '🏭  Industrials', etf: 'XLI' },
    { name: '🍎  Consumer Staples', etf: 'XLP' },
    { name: '⚡  Energy', etf: 'XLE' },
    { name: '💡  Utilities', etf: 'XLU' },
    { name: '🏠  Real Estate', etf: 'XLRE' },
    { name: '🔧  Materials', etf: 'XLB' },
  ];

  const symbols = sectorData.map(sector => sector.etf);
  const { data: quotesResponse, isPending, isError } = useQuery({
    queryKey: ['sectorQuotes'],
    queryFn: () => fetch(market_quote_api_path({ symbols })).then(res => res.json())
  });

  useEffect(() => {
    if (chartRef.current && quotesResponse && !isPending && !isError) {
      const chart = echartsInit(chartRef.current);
      const green = '#009963';
      const red = '#bc2727';

      const sectorWeightings = sectorData.map(sector => {
        const quote = quotesResponse.quotes?.[sector.etf] || {};
        return {
          ...sector,
          change: parseFloat(quote.changePercent),
          itemStyle: { color: quote?.changePercent >= 0 ? green : red }
        };
      }).sort((a, b) => b.change - a.change);

      const textStyle = {
        fontFamily: "Inter, sans-serif",
        fontSize: 18
      };
      chart.setOption({
        textStyle: textStyle,
        grid: {
          top: '0%',
          left: '8%',
          right: '0%',
          bottom: '0%',
          containLabel: true
        },
        xAxis: {
          type: 'value',
          show: false,
          min: 0,
          max: function (value) {
            return Math.max(Math.abs(value.min), Math.abs(value.max));
          },
          axisLabel: {
            formatter: '{value}%'
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'category',
          data: sectorWeightings.map(item => item.name),
          inverse: true,
          position: 'right',
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: true,
            ...textStyle,
            fontWeight: 'bold',
            formatter: (value, index) => {
              const sector = sectorWeightings[index];
              const change = sector.change.toFixed(2);
              return `${sector.change >= 0 ? '+' : ''}${change}%`;
            },
            color: (value, index) => {
              const sector = sectorWeightings[index];
              return sector.change >= 0 ? green : red;
            },
            align: 'right',
            padding: [0, -60, 0, 0]
          }
        },
        series: [{
          type: 'bar',
          data: sectorWeightings.map(item => ({
            value: Math.abs(item.change),
            itemStyle: { ...item.itemStyle, borderRadius: [0, 16, 16, 0] }
          })),
          label: {
            show: true,
            offset: [-30, 0],
            position: 'insideLeft',
            formatter: (params) => {
              const sector = sectorWeightings[params.dataIndex];
              return sector.name;
            },
            color: '#eeeeee'
          },
          barWidth: '85%',
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      });

      chart.on('click', (params) => {
        const sector = sectorWeightings[params.dataIndex];
        navigate(show_symbol_path({ symbol: sector.etf }));
      });

      const handleResize = () => chart.resize();
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
        chart.dispose();
      };
    }
  }, [quotesResponse, isPending, isError]);

  if (isPending) {
    return (
      <div className='flex flex-col mx-4 mt-4'>
        <div className='pl-1 mb-2'>
          <span className='section-header'>Market Sectors</span>
        </div>
        <div className='w-full h-[400px] rounded-xl bg-black flex flex-col gap-y-2'>
          {[...Array(10)].map((_, i) => (
            <div key={i} className="flex items-center">
              <div className={`bg-zinc-700 h-7 rounded animate-pulse ${[
                  'w-64',
                  'w-56',
                  'w-52',
                  'w-48',
                  'w-32',
                  'w-28',
                  'w-24',
                  'w-36',
                  'w-40',
                  'w-44',
                ][i] || 'w-24'
                }`}></div>
            </div>
          ))}
        </div>
      </div>
    );
  }
  if (isError) return <div>Error fetching data</div>;

  return (
    <div className='flex flex-col mx-4 mt-4'>
      <div className='pl-1 mb-2'>
        <span className='section-header'>Market Sectors</span>
      </div>
      <div className={`${className} w-full h-[400px] rounded-2xl overflow-hidden`} ref={chartRef} />
    </div>
  );
};

export default MarketSectors;