import DateCountdown from './date_countdown';

class FedMeeting {
  constructor() {
    this.meetings = [
      '2024-12-17T14:30:00-05:00',
      '2025-01-28T14:30:00-05:00',
      '2025-03-18T14:30:00-05:00',
      '2025-05-06T14:30:00-05:00',
      '2025-06-17T14:30:00-05:00',
      '2025-07-29T14:30:00-05:00',
      '2025-09-16T14:30:00-05:00',
      '2025-10-28T14:30:00-05:00',
      '2025-12-09T14:30:00-05:00',
    ];
  }

  getNextMeeting() {
    return DateCountdown.findNextDate(this.meetings);
  }
}

export default FedMeeting;