import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import {
  market_upcoming_earnings_api_path,
  show_symbol_path,
  symbol_image_path,
} from '../routes';
import { navigate } from '../navigator';
import { ClockIcon, SunIcon, MoonIcon, ChevronDoubleUpIcon, ChevronDoubleDownIcon } from '@heroicons/react/24/solid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { motion } from 'framer-motion';

const LoadingState = () => (
  <div className="flex flex-col">
    <Header />
    <div className="flex items-center justify-center h-24">
      <div className='spinner w-5 h-5'></div>
    </div>
  </div>
);

const ErrorState = () => (
  <div>Error loading upcoming earnings.</div>
);

const Header = () => (
  <div className="mt-4 mb-2 mx-4 flex flex-row gap-x-2">
    <div className="hidden w-4 h-4">
      <ClockIcon className="w-4 h-4 text-zinc-300" />
    </div>
    <span className="section-header">Upcoming Earnings</span>
  </div>
);

const TimeOfDayIcon = ({ timeOfDay }) => (
  timeOfDay === 'Before Open' ? (
    <SunIcon className="w-4 h-4 text-yellow-500" />
  ) : (
    <MoonIcon className="w-4 h-4 text-zinc-400" />
  )
);

const EarningsListItem = ({ er }) => (
  <div className="flex flex-row items-center cursor-pointer"
    onClick={() => navigate(show_symbol_path({ symbol: er.ticker }))}
  >
    <div className="flex items-center">
      <div className="h-[35px] w-[35px]">
        <img className="h-full w-full"
          src={symbol_image_path({ symbol: er.ticker })}
          alt={er.ticker}
        />
      </div>
    </div>
    <div className="ml-2 first-letter:hidden flex flex-col">
      <span className=" text-sky-300 font-bold">{er.ticker}</span>
      <span className="-mt-1 text-zinc-400 truncate w-20">
        {er.company_name}
      </span>
    </div>
  </div>
);

const EarningsPeriod = ({ timeOfDay, earningsGroup }) => (
  <div className="w-[165px]">
    <div className="py-1 px-1 flex flex-row text-zinc-500 min-w-[120px]">
      <div className="w-4 h-4 mr-1">
        <TimeOfDayIcon timeOfDay={timeOfDay} />
      </div>
      <span className="font-semibold uppercase text-xs">{timeOfDay}</span>
    </div>
    <div className="flex flex-row flex-wrap md:flex-col">
      {earningsGroup.map((er) => (
        <EarningsListItem key={er.ticker} er={er} />
      ))}
    </div>
  </div>
);

const EarningsDay = ({ date, earnings }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const INITIAL_ITEMS_TO_SHOW = 2;
  
  const hasMoreItems = Object.values(groupByTimeOfDay(earnings))
    .flat()
    .length > INITIAL_ITEMS_TO_SHOW;

  return (
    <div key={date}>
      <div className="text-left px-2 py-1 bg-zinc-900 sticky top-0 rounded-t-lg">
        <span className="text-zinc-400 uppercase text-sm">{formatDate(date, 'ddd')}</span>
        <span className="text-zinc-500 uppercase text-sm ml-1">{formatDate(date, 'MMM D')}</span>
      </div>
      <motion.div
        initial={{ height: "130px" }}
        animate={{ height: isExpanded ? "auto" : "130px" }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        className="flex flex-col gap-y-1 overflow-hidden"
      >
        {Object.entries(groupByTimeOfDay(earnings))
          .sort()
          .reverse()
          .map(([timeOfDay, earningsGroup]) => (
            <EarningsPeriod
              key={timeOfDay}
              timeOfDay={timeOfDay}
              earningsGroup={earningsGroup}
            />
          ))}
      </motion.div>
      {hasMoreItems && (
        <motion.button
          className="w-full py-1 text-zinc-400 hover:text-dgreen-300 bg-zinc-900 rounded-b-lg"
          onClick={() => setIsExpanded(!isExpanded)}
          whileHover={{ backgroundColor: "rgba(6, 78, 59, 0.7)" }}
          whileTap={{ scale: 0.98 }}
        >
          {isExpanded ? (
            <ChevronDoubleUpIcon className="w-3 h-3 mx-auto" />
          ) : (
            <ChevronDoubleDownIcon className="w-3 h-3 mx-auto" />
          )}
        </motion.button>
      )}
    </div>
  );
};

const UpcomingEarnings = () => {
  const { data: earningsData, isLoading, isError } = useQuery({
    queryKey: ['upcomingEarnings'],
    queryFn: () => fetch(market_upcoming_earnings_api_path()).then((res) => res.json())
  });

  if (isLoading) return <LoadingState />;
  if (isError) return <ErrorState />;

  const groupedByDate = groupByDate(earningsData.upcoming_earnings);

  return (
    <div className="w-100vw md:w-full">
      <Header />
      <Swiper
        grabCursor={true}
        spaceBetween={8}
        slidesPerView={3}
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
        className="w-full h-fit"
      >
        {Object.entries(groupedByDate)
          .sort((a, b) => new Date(a[0]) - new Date(b[0]))
          .map(([date, earnings]) => (
            <SwiperSlide key={date}>
              <EarningsDay date={date} earnings={earnings} />
            </SwiperSlide>
          ))}
      </Swiper>
    </div>
  );
};

// Helper functions

const groupByDate = (earnings) => {
  if (!Array.isArray(earnings)) {
    return {};
  }
  return earnings.reduce((acc, er) => {
    const date = new Date(er.report_time).toDateString();
    acc[date] = acc[date] || [];
    acc[date].push(er);
    return acc;
  }, {});
};

const groupByTimeOfDay = (earnings) => {
  return earnings.reduce((acc, er) => {
    const reportHour = new Date(er.report_time).getHours();
    const timeOfDay = reportHour < 12 ? 'Before Open' : 'After Close';
    acc[timeOfDay] = acc[timeOfDay] || [];
    acc[timeOfDay].push(er);
    return acc;
  }, {});
};

const formatDate = (dateStr, format) => {
  const date = new Date(dateStr);
  if (format === 'ddd') {
    return date.toLocaleDateString(undefined, { weekday: 'short' });
  } else if (format === 'MMM D') {
    return date.toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
  }
  return dateStr;
};

export default UpcomingEarnings;
