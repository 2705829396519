class DateCountdown {
  static getTimeUntil(targetDate) {
    const now = new Date();
    const etNow = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));
    const targetDateTime = new Date(targetDate);
    
    const diffInMinutes = (targetDateTime - etNow) / (1000 * 60);
    
    if (diffInMinutes < 0) return null;

    let timeUntil;
    if (diffInMinutes >= 1440) {
      const days = Math.ceil(diffInMinutes / 1440);
      timeUntil = `in ${days} days`;
    } else if (diffInMinutes >= 60) {
      const hours = Math.ceil(diffInMinutes / 60);
      timeUntil = `in ${hours} hours`;
    } else {
      timeUntil = `in ${Math.ceil(diffInMinutes)} minutes`;
    }

    return {
      dateTime: targetDateTime,
      timeUntil,
      daysUntil: Math.ceil(diffInMinutes / 1440)
    };
  }

  static findNextDate(dates) {
    const now = new Date();
    const etNow = new Date(now.toLocaleString('en-US', { timeZone: 'America/New_York' }));

    const nextDate = dates.find(date => new Date(date) > etNow);
    if (!nextDate) return null;

    return this.getTimeUntil(nextDate);
  }
}

export default DateCountdown; 