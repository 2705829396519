import React from 'react';
import { RocketLaunchIcon } from '@heroicons/react/24/solid';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';

const Card = ({ text, color = 'zinc', background = "#0F1E44", cardClassName = '' }) => {
  return (<>
    <div className={`w-[140px] h-[100px] flex flex-col justify-between rounded-lg py-4 px-4 ${cardClassName}`} style={{ background: background }}>
      <div className={`text-sm font-semibold text-zinc-300`}>{text}</div>
      <div className='grow'></div>
      <div className='flex flex-row items-center justify-end pt-1'>
        <div className='size-5'><RocketLaunchIcon /></div>
        <div className='grow'></div>
        <div className="text-zinc-200 opacity-40 text-2xl font-light cursor-pointer select-none">›</div>
      </div>
    </div>
  </>)
}

const PromoCards = ({ className = '', cardClassName = 'w-[185px]' }) => {
  const slides = [
    {
      text: "Create your first trade",
      background: '#002C3F',
    },
    {
      text: "Connect your broker",
      background: '#0F1E44',
    },
    {
      text: "Discover winning trades",
      background: '#0F1E44',
      className: "mr-4"
    }
  ];
  return (
    <div className={`w-full pb-2 ${className}`}>
      <Swiper
        grabCursor={true}
        pagination={{
          clickable: false,
        }}
        spaceBetween={0}
        slidesPerView={'auto'}
        modules={[Pagination]}
        className="w-full h-fit flex flex-row justify-start items-center">
        {slides.map((slide, index) => (
          <SwiperSlide key={index} className={`ml-4 !w-auto items-center justify-center rounded-2xl shadow-md ${slide.className || ''}`}>
            <Card
              cardClassName={cardClassName}
              text={slide.text}
              background={slide.background}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default PromoCards;
