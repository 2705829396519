import { Controller } from "@hotwired/stimulus"
import HomeComponent from "../components/home_component";
import React from 'react'
import {createRoot} from 'react-dom/client';

// Connects to data-controller="home"
export default class extends Controller {
  connect() {
    createRoot(this.element).render((
      <HomeComponent />
    ));
  }
}
