import DateCountdown from './date_countdown';

class EmploymentRelease {
  constructor() {
    this.releases = [
      '2024-11-01T08:30:00',
      '2024-12-06T08:30:00',
      '2025-01-10T08:30:00',
      '2025-02-07T08:30:00',
      '2025-03-07T08:30:00',
      '2025-04-04T08:30:00',
      '2025-05-02T08:30:00',
      '2025-06-06T08:30:00',
      '2025-07-03T08:30:00',
      '2025-08-01T08:30:00',
      '2025-09-05T08:30:00',
      '2025-10-03T08:30:00',
      '2025-11-07T08:30:00',
      '2025-12-05T08:30:00',
    ];
  }

  getNextRelease() {
    return DateCountdown.findNextDate(this.releases);
  }
}

export default EmploymentRelease; 