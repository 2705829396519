import React from 'react';
import {
  BanknotesIcon,
  CalendarDaysIcon,
  UserGroupIcon,
  CpuChipIcon,
  ChartBarIcon,
  ArrowsRightLeftIcon
} from '@heroicons/react/24/solid';

const ShortcutItem = ({ icon: Icon, label }) => (
  <div className="cursor-pointer flex flex-col items-center gap-2">
    <div className="w-10 h-10 bg-zinc-800 p-2 rounded-full">
      <Icon className="w-full h-full text-zinc-400" />
    </div>
    <div className="-mt-1.5 text-xs text-zinc-400">{label}</div>
  </div>
);

const Shortcuts = () => {
  const shortcuts = [
    { icon: ArrowsRightLeftIcon, label: 'Trades' },
    { icon: CalendarDaysIcon, label: 'Earnings' },
    { icon: UserGroupIcon, label: 'Groups' },
    { icon: CpuChipIcon, label: 'Bots' },
    { icon: ChartBarIcon, label: 'P&L' },
  ];

  return (
    <div className="my-2 py-2 px-6">
      <div className="flex justify-between">
        {shortcuts.map((shortcut, index) => (
          <ShortcutItem key={index} {...shortcut} />
        ))}
      </div>
    </div>
  );
};

export default Shortcuts; 