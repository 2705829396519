import RangeController from "./range_controller"
import { ColorType, createChart, CrosshairMode } from 'lightweight-charts'
import MrcEvent from "./mrc_event";
import MrcCache from './mrc_cache'
import _ from "underscore";

// Connects to data-controller="balance-chart"
export default class extends RangeController {
  static targets = ['chart']
  static classes = ['loading', 'selected']
  static values = {
    height: Number,
    width: Number,
  }

  connect() {
    this.width = Math.min(window.innerWidth, this.element.scrollWidth, this.widthValue)
    this.height = this.heightValue
    this.chart = this.createChart(this.width, this.height)
    this.chart.timeScale().subscribeVisibleLogicalRangeChange(range => {
      this.refreshDataIfNeeded()
    });

    this.params = {}
    this.populateCharts(this.params)
    super.connect()
  }

  populateCharts(params) {
    let dataUrl = this.element.dataset.dataUrl;
    if (!_.isEmpty(params)) {
      const url = new URL(dataUrl, window.location.origin)
      for (const key in params) {
        url.searchParams.set(key, params[key])
      }
      dataUrl = url.href
      this.element.setAttribute("data-data-url", dataUrl)
    }

    this.showLoading()
    MrcCache.fetchContent(dataUrl, (response) => response.json(), 30000)
      .then((data) => {
        let balanceData = data.balance_history
        if (this.balanceSeries === undefined) {
          this.balanceSeries = this.chart.addAreaSeries({
            topColor: 'rgba(33,245,0,0.4)',
            bottomColor: 'rgba(8,245,0,0.16)',
            lineColor: 'rgb(50,245,50)',
            lineWidth: 2,
          })
        }
        this.balanceSeries.setData(balanceData)
        this.chart.timeScale().fitContent()
        this.hideLoading()
      })
  }

  createChart(width, height) {
    return createChart(this.chartTarget, {
      width: width,
      height: height,
      autoSize: true,
      layout: {
        background: { type: ColorType.Solid, color: '#000000' },
        textColor: '#999999',
      },
      crosshair: {
        mode: CrosshairMode.Normal,
        horzLine: {
          // visible: false,
          labelBackgroundColor: "#48e8cc",
        },
        vertLine: {
          // visible: false,
          labelBackgroundColor: "#48e8cc",
        },
      },
      grid: {
        vertLines: {
          color: 'rgba(255, 255, 255, 0)',
        },
        horzLines: {
          color: 'rgba(255, 255, 255, 0.1)',
        },
      },
      rightPriceScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
      },
      timeScale: {
        borderColor: 'rgba(197, 203, 206, 0.8)',
        timeVisible: true,
        tickMarkFormatter: function(timestamp) {
          let date = new Date(timestamp * 1000); // assuming the timestamp is in UNIX format
          let time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
          return `${date.getMonth() + 1}/${date.getDate()} ${time}`
        },
      },
      localization: {
        timeFormatter: function(timestamp) {
          let date = new Date(timestamp * 1000); // assuming the timestamp is in UNIX format
          let time = date.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
          return `${date.getMonth() + 1}/${date.getDate()} ${time}`
        },
      }
    })
  }

  refreshDataIfNeeded() {
    if (this.refreshDataTimeout) {
      return
    }
    this.refreshDataTimeout = (() => {
      this.refreshDataTimeout = null
      const range = this.chart.timeScale().getVisibleLogicalRange()
      const barsInfo = this.balanceSeries.barsInLogicalRange(range)
      if (barsInfo) {
        const smallValue = Math.abs(barsInfo.barsBefore) > 0 && Math.abs(barsInfo.barsBefore) < 1
        if (barsInfo.barsBefore < 0 && !smallValue) {
          const totalRange = Math.abs(barsInfo.barsBefore) + Math.abs(barsInfo.barsAfter)
          const extraTime = (barsInfo.to - barsInfo.from) * (Math.abs(barsInfo.barsBefore) / parseFloat(totalRange))
          const nextFrom = barsInfo.from - extraTime
          if (extraTime > 0) {
            this.params.start_time = new Date(nextFrom * 1000).toISOString()
            this.populateCharts(this.params)
          }
        }
      }
    }).bind(this)
    setTimeout(this.refreshDataTimeout, 1000)
  }
}
