import React, { useEffect, useRef } from 'react';
import { init as initECharts, graphic } from 'echarts';

const SparklineMock = ({ strokeWidth = 2, className = "h-4", color = '#2fe898', maxSamples = 20 }) => {
  const generateData = () => {
    const data = [];
    const noiseFactor = 5;
    for (let i = 0; i < 40; i++) {
      const baseValue = 95 + 5 * Math.sin(i / 5);
      const noise = (Math.random() - 0.5) * noiseFactor;
      data.push(baseValue + noise);
    }
    return data;
  };
  const chartRef = useRef(null);
  let data = [];

  useEffect(() => {
    if (chartRef.current && chartRef.current.clientHeight > 0) {
      const chart = initECharts(chartRef.current);
      data = generateData()
      data = data.length > maxSamples ? data.filter((_, index) => index % Math.ceil(data.length / maxSamples) === 0) : data;
      const option = {
        animation: false,
        grid: {
          left: 0,
          right: 0,
          top: 0,
          bottom: 0
        },
        xAxis: {
          type: 'category',
          show: false,
          boundaryGap: false
        },
        yAxis: {
          type: 'value',
          show: false,
          min: 'dataMin',
          max: 'dataMax'
        },
        series: [{
          type: 'line',
          data: data,
          showSymbol: false,
          lineStyle: {
            color: color,
            width: strokeWidth
          },
          areaStyle: {
            color: new graphic.LinearGradient(0, 0, 0, 1, [
              { offset: 0, color: color + '20' },
              { offset: 1, color: color + '00' }
            ])
          }
        }]
      };

      chart.setOption(option);
      return () => chart.dispose();
    }
  }, []);

  return <div ref={chartRef} className={`w-full ${className}`} />;
};

export default SparklineMock;