import React, { useState, useEffect } from 'react'
import NavigationIcon from './navigation_icon'
import AppBarSubmenu from './app_bar_submenu'
import SearchInput from './search_input'
import { submenuItems } from './app_bar_submenu';
import Tracker from './tracker';

const AppBarNavigation = ({ links, onSelect }) => {
  const getSelectedLink = () => links.find(link => link.href === window.location.pathname);

  const [mounted, setMounted] = useState(false);
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  const [selectedLink, setSelectedLink] = useState(getSelectedLink());

  const selectedLinkName = selectedLink?.name
  useEffect(() => {
    setMounted(true);
    const handleLocationChange = (e) => {
      setCurrentPath(window.location.pathname);
      setSelectedLink(getSelectedLink());
    };

    document.addEventListener('turbo:load', handleLocationChange);
    document.addEventListener('turbo:visit', handleLocationChange);
    document.addEventListener('turbo:frame-load', handleLocationChange);
    return () => {
      document.removeEventListener('turbo:load', handleLocationChange);
      document.removeEventListener('turbo:visit', handleLocationChange);
      document.removeEventListener('turbo:frame-load', handleLocationChange);
    };
  }, [currentPath, selectedLink]);

  const handleTouchStart = (e, link) => {
    const element = e.currentTarget;
    element.classList.add('ripple', 'expand-in');
    element.addEventListener('animationend', () => {
      element.classList.remove('ripple', 'expand-in');
    }, { once: true });
    setCurrentPath(window.location.pathname);
    setSelectedLink(link);
    Tracker.track('app_bar.nav_click', { link: link.name });
    Turbo.visit(link.href, { action: 'advance', frame: 'main' });
  };

  const menuHiddenClass = 'opacity-0 h-0';
  const menuVisibleClass = 'opacity-100 h-14';

  const isSubmenuPathActive = () => {
    return submenuItems.some(item => item.path === currentPath);
  };

  const shouldShowSubmenu = isSubmenuPathActive() || selectedLinkName === 'Home'
  const secondaryNavClass = shouldShowSubmenu
    ? `${mounted ? menuVisibleClass : menuHiddenClass}`
    : menuHiddenClass;

  const renderSecondaryContent = () => {
    if (isSubmenuPathActive()) {
      return <AppBarSubmenu />;
    }

    switch (selectedLinkName) {
      case 'Home':
        return <AppBarSubmenu />;
      case 'Explore':
        return null;
        // return (
        //   <div className='bg-black w-full h-full flex flex-row items-center justify-center'>
        //     <SearchInput inputClassName='w-full mx-4' />
        //   </div>
        // );
      default:
        return null;
    }
  };

  return (
    <>
      <div className='flex-flex-col'>
        <div className={`${secondaryNavClass} w-full transform transition-all duration-300 ease-out overflow-hidden`}>
          {renderSecondaryContent()}
        </div>
        <div className="h-14 border-t border-zinc-900 w-full flex flex-row md:hidden">
          {links.map((link) => {
            const isSelected = selectedLink === link;
            return (
              <a
                key={link.name}
                className={`nav-link relative overflow-hidden ${isSelected ? 'nav-selected-tab' : 'nav-unselected'}`}
                onClick={(e) => handleTouchStart(e, link)}
                onTouchStart={(e) => handleTouchStart(e, link)}
                href={link.href}
                data-turbo-frame="main"
                data-turbo-action="advance">
                <div className="relative">
                  {link.badge_count > 0 && (
                    <span
                      style={{ top: '-4px', right: '-10px' }}
                      className="absolute items-center rounded-full bg-rose-800 px-1.5 ml-1 py-0.5 text-xs font-medium text-zinc-300">
                      {link.badge_count}
                    </span>
                  )}
                  <div className="mt-1 w-fit h-fit flex items-center justify-center">
                    <NavigationIcon className='nav-icon' icon={link.icon} isSelected={isSelected} />
                  </div>
                </div>
                <span className="nav-label -mt-1">{link.name}</span>
              </a>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default AppBarNavigation 