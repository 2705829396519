import { useQuery } from '@tanstack/react-query';
import { watchlist_list_api_path } from '../routes';

const WATCHLIST_LIST_QUERY_KEY = 'watchlist_list' as const;

const useWatchlist = () => {
  return useQuery({
    queryKey: [WATCHLIST_LIST_QUERY_KEY],
    queryFn: () =>
      fetch(watchlist_list_api_path()).then((response) => response.json())
  });
};

export { useWatchlist, WATCHLIST_LIST_QUERY_KEY };