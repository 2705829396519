import { BellIcon, CalendarIcon, ClockIcon, NewspaperIcon } from '@heroicons/react/24/solid';
import React from 'react';
import SlideTransition from './slide_transition';
import FedMeeting from '../utils/fed_meeting';
import EmploymentRelease from '../utils/employment_release';
import moment from 'moment';

const WelcomeHeader = () => {
  const nextFedMeeting = new FedMeeting().getNextMeeting();
  const nextEmploymentRelease = new EmploymentRelease().getNextRelease();

  const notifications = [
    {
      icon: <ClockIcon />,
      message: <>
        <div className='ml-1 flex items-center flex-row gap-x-2'>
          <div className='text-zinc-300 hidden'>Today</div>
          <div className='text-zinc-200'>
            {moment().format('h:mm A')}
          </div>
          <div className='text-zinc-400'>
            {moment().format('dddd, MMMM D')}
          </div>
        </div>
      </>,
      color: "zinc"
    },
    nextEmploymentRelease && nextEmploymentRelease.daysUntil < 5 && {
      icon: <NewspaperIcon />,
      message: <>Non-farm payroll will be released in <span className='text-yellow-300'>{nextEmploymentRelease.timeUntil}</span></>,
      color: "zinc"
    },
    nextFedMeeting && {
      icon: <CalendarIcon />,
      message: <>The next Fed meeting is in <span className={`${nextFedMeeting.daysUntil < 5 ? 'text-yellow-300' : 'text-sky-300'}`}>{nextFedMeeting.daysUntil} days</span></>,
      color: "zinc"
    },
  ].filter(Boolean);

  return (
    <div className='flex flex-col mx-4 mb-2'>
      <SlideTransition timeout={5000}>
        {notifications.map((notification, index) => (
          <div key={index} className='flex flex-row gap-x-1 items-start'>
            <div className={`text-${notification.color}-500 w-5 h-5`}>
              {notification.icon}
            </div>
            <div className={`text-${notification.color}-400 text-sm truncate`}>
              {notification.message}
            </div>
          </div>
        ))}
      </SlideTransition>
    </div>
  );
};

export default WelcomeHeader;