import { useState, useEffect } from 'react';
import React from 'react';
import { Transition } from '@headlessui/react';

const SlideTransition = ({ className = '', children, timeout = 4000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const childrenArray = React.Children.toArray(children);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % childrenArray.length);
    }, timeout);

    return () => clearInterval(timer);
  }, [childrenArray.length]);

  return (
    <div className={`flex flex-col relative h-6 ${className} overflow-hidden`}>
      {childrenArray.map((child, index) => (
        <Transition
          show={currentIndex === index}
          enter="transition-all duration-500"
          enterFrom="opacity-0 translate-y-2"
          enterTo="opacity-100 translate-y-0"
          leave="transition-all duration-400"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-2"
          key={index}>
          <div className='w-full'>
            {child}
          </div>
        </Transition>
      ))}
    </div>
  );
};

export default SlideTransition; 