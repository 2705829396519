import { Controller } from "@hotwired/stimulus"
import { createRoot } from 'react-dom/client'
import React from 'react'
import AppBarNavigation from '../components/app_bar_navigation'
export default class extends Controller {
  static values = {
    links: Array
  }

  connect() {
    this.root = createRoot(this.element)
    this.renderNavigation()
  }

  disconnect() {
    this.root?.unmount()
  }

  renderNavigation() {
    this.root.render(
      <AppBarNavigation 
        data-turbo-permanent
        links={this.linksValue}
        onSelect={this.handleSelect}
        selectedPath={window.location.pathname}
      />
    )
  }

  handleSelect = (event, href) => {
    this.linksValue = this.linksValue.map(link => ({
      ...link,
      selected: link.href === href
    }))
    this.renderNavigation()
    // navigation works through regular link clicks
    // alternative: Handle navigation via Turbo 
    // Turbo.visit(href)
  }
}