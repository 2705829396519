import React, { useState, useRef, useEffect, useMemo } from 'react';
import { MagnifyingGlassIcon, PlusIcon, ArrowUpIcon, ChevronUpDownIcon } from '@heroicons/react/24/solid';
import { useQuery } from '@tanstack/react-query';
import { watchlist_api_path } from "../routes";
import AddSymbolDialog from './add_symbol_dialog';
import Blaze from '../blaze';
import WatchlistItem from './watchlist_item';
import { Button } from "./catalyst/button";
import { FixedSizeList } from 'react-window';
import { Stock } from '../types/stock';
import { Switch } from './catalyst/switch';
import Tracker from './tracker';

const WatchList = ({ className = '' }) => {
  const [sortField, setSortField] = useState<'name' | 'change' | 'price' | 'iv30'>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [showIV, setShowIV] = useState(false);
  const listRef = useRef<FixedSizeList>(null);
  const scrollOffsetRef = useRef<number>(0);

  const { data, refetch, isLoading, dataUpdatedAt } = useQuery({
    queryKey: ['watchlist'],
    queryFn: () => fetch(watchlist_api_path()).then(res => res.json()),
    refetchInterval: 8000
  });

  useEffect(() => {
    if (dataUpdatedAt && scrollOffsetRef.current > 0 && listRef.current) {
      listRef.current.scrollTo(scrollOffsetRef.current);
    }
  }, [dataUpdatedAt]);

  const stocks: Stock[] = data ? data.watchlist : [];
  const sortedStocks = useMemo(() => {
    return [...stocks].sort((a, b) => {
      switch (sortField) {
        case 'name':
          return sortDirection === 'asc'
            ? a.symbol.localeCompare(b.symbol)
            : b.symbol.localeCompare(a.symbol);
        case 'change':
          return sortDirection === 'asc'
            ? a.changePercent - b.changePercent
            : b.changePercent - a.changePercent;
        case 'price':
          return sortDirection === 'asc'
            ? a.latestPrice - b.latestPrice
            : b.latestPrice - a.latestPrice;
        case 'iv30':
          return sortDirection === 'asc'
            ? a.iv_edge - b.iv_edge
            : b.iv_edge - a.iv_edge;
        default:
          return 0;
      }
    });
  }, [stocks, sortField, sortDirection]);

  const LoadingState = () => (
    <div className="flex justify-center items-center py-2 gap-x-2 text-zinc-500">
      <div className="w-5 h-5 spinner"></div>
      <div className="text-zinc-400 text-sm">Loading Watchlist</div>
    </div>
  );

  const EmptyState = () => (
    <div className="flex flex-col items-center py-4 bg-zinc-900 text-zinc-300">
      <p className="mb-2 text-zinc-400 text-lg">Your watchlist is empty</p>
      <Button
        onClick={() => setIsAddDialogOpen(true)}>
        <PlusIcon className="w-4 h-4 mr-2" />
        <span className="text-sm text-zinc-300">Add your first symbol</span>
      </Button>
    </div>
  );

  const handleSort = (field: 'name' | 'change' | 'price' | 'iv30') => {
    if (sortField === field) {
      setSortDirection(prev => prev === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection(field === 'name' ? 'asc' : 'desc');
    }
    listRef.current?.scrollTo(0);
    Tracker.track('watchlist.sort', { field: field, direction: sortDirection });
  };

  const WatchlistTableHeader = () => {
    const columnClasses = "text-zinc-400 text-xs font-medium flex items-center cursor-pointer";
    const renderSortIcon = (field) => {
      if (sortField === field) {
        return <ArrowUpIcon className={`w-3 h-3 ml-0.5 ${sortDirection === 'desc' && 'rotate-180'}`} />;
      }
      return <ChevronUpDownIcon className="w-4 h-4 text-zinc-400" aria-hidden="true" />;
    };

    return (
      <div className="w-full flex flex-row bg-zinc-900 border-b border-zinc-800">
        <div className={`pl-4 py-2 ${columnClasses} grow`} onClick={() => handleSort('name')}>
          Name
          {renderSortIcon('name')}
        </div>
        <div className={`text-right justify-end w-20 py-2 pl-2 ${columnClasses}`} onClick={() => handleSort('price')}>
          Price
          {renderSortIcon('price')}
        </div>
        <div className={`text-right justify-end w-[88px] py-2 px-2 ${columnClasses}`} onClick={() => handleSort('change')}>
          Change
          {renderSortIcon('change')}
        </div>
        {showIV && (
          <div className={`text-right justify-end w-[50px] py-2 ${columnClasses}`} onClick={() => handleSort('iv30')}>
            IV
            {renderSortIcon('iv30')}
          </div>
        )}
      </div>
    );
  };

  const estimatedRowHeight = 66;

  const WatchlistList = () => (
    <div className="flex flex-col">
      <WatchlistTableHeader />
      <FixedSizeList
        ref={listRef}
        height={Math.min(sortedStocks.length * estimatedRowHeight, estimatedRowHeight * 12)}
        overscanCount={3}
        width="100%"
        itemCount={sortedStocks.length}
        itemSize={estimatedRowHeight}
        initialScrollOffset={scrollOffsetRef.current}
        onScroll={({ scrollOffset }) => {
          scrollOffsetRef.current = scrollOffset;
        }}
      >
        {({ index, style }) => (
          <div style={style}>
            <WatchlistItem
              key={sortedStocks[index].symbol}
              stock={sortedStocks[index]}
              rowHeight={estimatedRowHeight}
              refetch={refetch}
              showIV={showIV}
            />
          </div>
        )}
      </FixedSizeList>
    </div>
  );

  const WatchlistBar = () => (
    <div className='flex flex-col pb-2 border-b border-zinc-800'>
      <div className="pl-2 flex flex-row items-center justify-between">
        <div className='section-header'>My Watchlist</div>
        <div className='flex flex-row items-center justify-end mr-0 divide-x divide-zinc-800'>
          <div className='px-3 cursor-pointer flex flex-row items-center gap-x-2'>
            <Switch checked={showIV} onChange={() => {
              setShowIV(!showIV)
              Tracker.track('watchlist.iv_toggle', { showIV: showIV })
            }} />
            <span className={`text-sm text-zinc-300`}>IV</span>
          </div>
          <div className='px-3 cursor-pointer' onClick={() => {
            setIsAddDialogOpen(true)
            Tracker.track('watchlist.open_symbol_search')
          }}>
            <MagnifyingGlassIcon className='w-6 h-6' />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={`mt-4 flex flex-col h-full ${className}`}>
      <WatchlistBar />
      <div className="flex flex-col">
        {isLoading ? <LoadingState /> :
          sortedStocks.length === 0 ? <EmptyState /> :
            <WatchlistList />}
      </div>
      <AddSymbolDialog
        isOpen={isAddDialogOpen}
        onClose={() => {
          setIsAddDialogOpen(false)
          refetch();
        }}
        onChange={() => { refetch(); }}
      />
    </div>
  );
};

export default WatchList;
