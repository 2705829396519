import { Controller } from "@hotwired/stimulus"
import React from "react"
import {createRoot} from 'react-dom/client';
import ExploreComponent from "../components/explore_component";

// Connects to data-controller="explore"
export default class extends Controller {
  connect() {
    this.root = createRoot(this.element)
    this.renderNavigation()
  }

  disconnect() {
    this.root?.unmount()
  }

  renderNavigation() {
    this.root.render(
      <ExploreComponent />
    )
  }
}
