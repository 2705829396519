import { Controller } from "@hotwired/stimulus"
import { createRoot } from 'react-dom/client'
import SearchAutocomplete from '../components/search_autocomplete'
import React from 'react'

export default class extends Controller {
  static targets = ["autocomplete"]

  disconnect() {
    if (this.root) {
      this.root.unmount()
    }
  }

  connect() {
    this.root = createRoot(this.autocompleteTarget)
    this.root.render(<SearchAutocomplete />)
  }
}
