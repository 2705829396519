import React from 'react';
import SlideTransition from './slide_transition';
import { useQuery } from '@tanstack/react-query';
import { market_quote_api_path, market_trending_api_path, show_symbol_path, symbol_image_path } from '../routes';
import { navigate } from "../navigator"
import SparklineChart from './sparkline_chart'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper/modules';
import { getColorClasses } from '../utils/colors';
import { useTrendingStocks } from '../hooks/use_trending_stocks';

const StockWidgetPending = ({ symbol }) => {
  return (<div className="min-h-28 h-fit p-3 text-sm rounded-lg bg-zinc-800">
    <div className="flex flex-col space-y-2">
      <span className="text-base font-semibold text-dblue-200">{symbol}</span>
      <div className="h-4 w-10 bg-zinc-700 animate-pulse rounded"></div>
      <div className="h-4 w-16 bg-zinc-700 animate-pulse rounded"></div>
      <div className="h-3 w-12 bg-zinc-700 animate-pulse rounded"></div>
    </div>
  </div>);
}

const StockWidget = ({ symbol }) => {
  const { data: stock, isPending } = useQuery({
    queryKey: ["market_quote", symbol],
    queryFn: () => fetch(market_quote_api_path({ symbols: [symbol] })).then(res => res.json())
  });

  if (isPending) {
    return <StockWidgetPending symbol={symbol} />;
  }

  const stockQuote = stock.quotes[symbol];

  const colors = getColorClasses(stockQuote.change);
  return (
    <div
      onClick={() => { navigate(show_symbol_path(stockQuote.symbol)) }}
      className={`cursor-pointer flex flex-col relative px-3 py-3 text-sm rounded-lg ${colors.background}`}>
      <div className="flex flex-row items-center">
        <img className='absolute opacity-90 top-3 right-3 w-6 h-6 rounded-full overflow-hidden' src={symbol_image_path(stockQuote.symbol)} />
        <span className="text-base font-semibold text-dblue-200">{stockQuote.symbol}</span>
      </div>
      <div className="text-sm text-zinc-400 truncate">{stockQuote.name}</div>
      <div className="text-base text-zinc-200">{stockQuote.latestPrice}</div>
      <SlideTransition timeout={5000}>
        <div className={`text-sm flex items-center space-x-1 ${colors.text}`}>
          <span>{parseFloat(stockQuote.change) >= 0 ? '▲' : '▼'}</span>
          <span className={`${colors.text}`}>{stockQuote.changePercent}%</span>
        </div>
        <div className={`text-sm flex items-center space-x-1 ${colors.text}`}>
          <span>{parseFloat(stockQuote.change) >= 0 ? '▲' : '▼'}</span>
          <span>{stockQuote.change}</span>
        </div>
      </SlideTransition>
      <SparklineChart symbol={stockQuote.symbol} className="h-8 mt-2" color={colors.chart} />
    </div>
  );
};

const TopStocksWidget = () => {
  const { data: trendingResponse, isPending } = useTrendingStocks();

  if (isPending) {
    return (<div className="mx-4 mt-4 flex flex-col">
      <div className="flex justify-between items-center mb-2">
        <h2 className="section-header">Top Stocks</h2>
      </div>
      <div className="space-x-2 grid grid-cols-3">
        <StockWidgetPending />
        <StockWidgetPending />
        <StockWidgetPending />
      </div>
    </div>);
  }

  const stocks = trendingResponse.trending;
  return (
    <div className="mt-4 flex flex-col">
      <div className="mx-4 flex justify-between items-center mb-2">
        <h2 className="section-header">Top Stocks</h2>
      </div>
      <Swiper
        grabCursor={true}
        pagination={{ clickable: false }}
        spaceBetween={8}
        slidesPerView={3}
        slidesOffsetBefore={16}
        slidesOffsetAfter={16}
        autoplay={{ delay: 8000 }}
        loop={true}
        modules={[Pagination, Autoplay]}
        className="w-full h-fit">
        {stocks.map(stock => (
          <SwiperSlide key={stock.symbol}>
            <StockWidget symbol={stock.symbol} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export { StockWidget, TopStocksWidget };